export enum FEATURE_FLAG {
  HUBS_FEATURE = 'hubs_feature',
  CUSTOMERS_FEATURE = 'customers',
  CARGAS_FEATURE = 'cargas_feature',
  PRICING_FEATURE = 'pricing_feature',
  REPORTS_FEATURE = 'reports_feature',
  PANDA_BOX_FEATURE = 'panda_box_feature',
  VS_EXPORT_FEATURE = 'vs_export_feature',
  ROUTING_FEATURE = 'simple_shift_feature',
  PDI_EXPORT_FEATURE = 'pdi_export_feature',
  DM2_EXPORT_FEATURE = 'dm2_export_feature',
  GL_MAPPING_FEATURE = 'gl_mapping_feature',
  BOL_PRICING_FEATURE = 'bol_pricing_feature',
  HANDLE_TENANT_ROLES = 'handle_tenant_roles',
  TANK_MONITORING = 'tank_monitoring_feature',
  DRIVER_RATE_FEATURE = 'driver_rate_feature',
  PRICING_TYPE_SETTING = 'pricing_type_setting',
  SPLASH_BLEND_FEATURE = 'splash_blend_feature',
  TOWER_EXPORT_FEATURE = 'towerx_export_feature',
  LUBE_DELIVERY_FEATURE = 'lube_delivery_feature',
  SHIFT_BUILDER_FEATURE = 'shift_builder_feature',
  ENABLE_ROUTING_FEATURE = 'enable_routing_feature',
  COMMON_CARRIER_FEATURE = 'common_carrier_feature',
  VENDOR_PRICING_FEATURE = 'vendor_pricing_feature',
  TRANSFER_ORDER_FEATURE = 'transfer_order_feature',
  CARDLOCK_FEATURE = 'card_lock_transaction_feature',
  PERTROL_EXPORT_FEATURE = 'petrolrx_export_feature',
  RECURRING_ORDER_FEATURE = 'recurring_order_feature',
  EXTRACTION_ORDER_FEATURE = 'extraction_order_feature',
  MINIMUM_TYPE_FEE_FEATURE = 'minimum_type_fee_feature',
  CREDIT_CARD_TYPE_FEATURE = 'credit_card_type_feature',
  DIVERSION_NUMBERS_FEATURE = 'diversion_numbers_feature',
  TAX_CALCULATION_TYPE_FEATURE = 'tax_calculation_type_feature',
  AM_PM_ORDER_GROUPING_FEATURE = 'am_pm_order_grouping_feature',
  CARD_LOCK_TRANSACTION_FEATURE = 'card_lock_transaction_feature',
  CREDIT_CONTROL_MANAGER_FEATURE = 'credit_control_manager_feature',
  DELIVERY_ONLY_CUSTOMER_FEATURE = 'delivery_only_customer_feature',
  PRODUCT_PURCHASE_LIMIT_FEATURE = 'product_purchase_limit_feature',
  IMPORT_RECURRING_ORDERS_FEATURE = 'import_recurring_orders_feature',
  NETSUITE_INTEGRATION_FEATURE = 'enable_netsuite_integration_feature',
  PRODUCT_NAME_WITH_ERP_ID_FEATURE = 'product_name_with_erp_id_feature',
  INHERIT_CUSTOMER_PRICING_FEATURE = 'inherit_customer_pricing_feature',
  ASSET_INVENTORY_TRACKING_FEATURE = 'asset_inventory_tracking_feature',
  ENABLE_2FA_AUTHENTICATION_FEATURE = 'enable_2fa_authentication_feature',
  MASTER_APPLY_APPLICABLE_TAX_FEATURE = 'master_apply_applicable_tax_feature',
  BULK_PLANT_SHIFT_AUTO_CREATE_FEATURE = 'bulk_plant_shift_auto_create_feature',
  SHOW_SHIPTO_IN_CUSTOMER_EDIT_FEATURE = 'show_shipto_in_customer_edit_feature',
  AUTO_POPULATE_ORDER_FEATURE = 'populate_load_order_from_previous_shift_feature',
  BULK_DOWNLOAD_DELIVERY_RECEIPT_FEATURE = 'bulk_download_delivery_receipt_feature',
  LOADING_ACCOUNT_INSTEAD_SUPPLIER_FEATURE = 'loading_account_instead_supplier_feature',
  ONLY_ASSIGNED_TRAILER_TO_TRACTOR_FEATURE = 'only_assigned_trailer_to_tractor_feature',
  SYNC_PURCHASE_ORDERS_TO_QUICKBOOKS_FEATURE = 'sync_purchase_orders_to_quickbooks_feature',
  Enforce_Single_Default_Delivery_Fee_Feature = 'enforce_single_default_delivery_fee_feature',
}

export enum USER_FEATURE_FLAG {
  Drivers = 'drivers',
}
